<template>
  <div>
    <Modal v-if="popupCancelMission" @closeModal="closePopup()">
      <template v-slot:icon>
        <vs-alert
          :active="errMsg"
          color="danger"
          icon="new_releases"
          class="mb-3"
        >
          <span>
            {{
              error_msg ? error_msg : "حدث خطأ ما برجاء المحاولة مره اخري"
            }}</span
          >
        </vs-alert>
        <svg
          width="91"
          height="91"
          viewBox="0 0 91 91"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M45.5 91C70.629 91 91 70.629 91 45.5C91 20.371 70.629 0 45.5 0C20.371 0 0 20.371 0 45.5C0 70.629 20.371 91 45.5 91Z"
            fill="#E80505"
            fill-opacity="0.1"
          />
          <path
            d="M58.385 34.2271L56.116 36.4861C56.86 37.2283 57.4502 38.1101 57.853 39.0808C58.2557 40.0515 58.463 41.0921 58.463 42.1431C58.463 43.194 58.2557 44.2346 57.853 45.2053C57.4502 46.176 56.86 47.0578 56.116 47.8001L58.385 50.069C59.4254 49.029 60.2508 47.7941 60.8139 46.435C61.377 45.0759 61.6668 43.6192 61.6668 42.1481C61.6668 40.6769 61.377 39.2202 60.8139 37.8611C60.2508 36.502 59.4254 35.2671 58.385 34.2271Z"
            fill="#E80505"
          />
          <path
            d="M62.912 29.8662L60.683 32.0952C63.3434 34.7623 64.8375 38.3756 64.8375 42.1427C64.8375 45.9098 63.3434 49.5232 60.683 52.1902L62.912 54.4192C64.525 52.8074 65.8045 50.8935 66.6775 48.787C67.5506 46.6805 67.9999 44.4225 67.9999 42.1422C67.9999 39.862 67.5506 37.604 66.6775 35.4975C65.8045 33.3909 64.525 31.4771 62.912 29.8652V29.8662Z"
            fill="#E80505"
          />
          <path
            d="M52.286 38.224V28H49.143L39.714 35.857H27.143L24 37.429V48.429L27.143 50L35 62.575H39.714L36.768 50H39.714L49.143 56.286H52.286V46.062C53.2048 45.9195 54.037 45.4383 54.6187 44.7129C55.2004 43.9876 55.4895 43.0708 55.429 42.143C55.4895 41.2152 55.2004 40.2984 54.6187 39.5731C54.037 38.8477 53.2048 38.3665 52.286 38.224Z"
            fill="#E80505"
          />
        </svg>
      </template>
      <template v-slot:content>
        <p class="modal-content">تأكيد الإلغاء</p>
      </template>
      <template v-slot:first-btn>
        <button class="first-btn">
          <span @click="cancelMission()">نعم تم الالغاء</span>
        </button>
      </template>
      <template v-slot:second-btn>
        <button @click="createNewTicket()" v-if="!loadingSubmitTicket">
          لا لم يتم الالغاء
        </button>
        <button v-else class="flex justify-center"><spinner-color /></button>
      </template>
    </Modal>
    <Modal v-if="popupImage" @closeModal="closePopup()">
      <template v-slot:content>
        <div class="modal-content mb-0">
          <SkeletonLoading
            :width="273"
            :height="273"
            :borderRadius="4"
            v-if="imgLoading"
          />
          <img
            v-show="!imgLoading"
            :src="cdnUrl + popupImageData.img"
            alt="Notification Image"
            @load="onImageLoad"
          />
          <p>{{ popupImageData.message }}</p>
        </div>
      </template>
    </Modal>

    <div class="notifications-header flex items-center justify-between">
      <h4 class="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-3"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            id="Path_1649"
            data-name="Path 1649"
            d="M452.2,187.375a9,9,0,1,0,9,9A9,9,0,0,0,452.2,187.375Zm0,16.594a7.594,7.594,0,1,1,7.594-7.594A7.59,7.59,0,0,1,452.2,203.969Zm2.813-9.264a2.817,2.817,0,0,1-2.109,2.723v1.144a.7.7,0,1,1-1.406,0v-1.758a.7.7,0,0,1,.7-.7,1.406,1.406,0,1,0-1.406-1.406.7.7,0,1,1-1.406,0,2.813,2.813,0,0,1,5.625,0Zm-1.933,5.977a.879.879,0,1,1-.879-.879A.879.879,0,0,1,453.083,200.682Z"
            transform="translate(-443.204 -187.375)"
            fill="#6e6b7b"
          />
        </svg>
        مركز الإشعارات
      </h4>
      <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
        <div
          v-if="!loading"
          class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
        >
          <span class="mr-2">{{ titleFilter }}</span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>
        <div
          v-else
          class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
        >
          <SpinnerColor />
        </div>

        <vs-dropdown-menu>
          <ul style="min-width: 11rem">
            <vs-dropdown-item
              class="m-0 p-0"
              v-for="(filter, index) in filterArrayNotification"
              :key="index"
            >
              <li
                @click="filterNotification(filter)"
                class="flex py-4 px-4 cursor-pointer profile-drop"
              >
                <span>{{ filter.title }}</span>
              </li>
              <vs-divider class="m-0" />
            </vs-dropdown-item>
          </ul>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
    <div
      class="notification"
      v-for="(n, index) in notfication"
      :key="index"
      @click="clickNotfication(n)"
    >
      <i class="icon-notification">
        <svg
          v-if="n.img"
          width="16"
          height="16"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 7.37722C1 7.0269 1 6.85174 1.01462 6.70421C1.1556 5.28127 2.28127 4.1556 3.70421 4.01462C3.85174 4 4.03636 4 4.40558 4C4.54785 4 4.61899 4 4.67939 3.99634C5.45061 3.94963 6.12595 3.46288 6.41414 2.746C6.43671 2.68986 6.45781 2.62657 6.5 2.5C6.54219 2.37343 6.56329 2.31014 6.58586 2.254C6.87405 1.53712 7.54939 1.05037 8.32061 1.00366C8.38101 1 8.44772 1 8.58114 1H13.4189C13.5523 1 13.619 1 13.6794 1.00366C14.4506 1.05037 15.126 1.53712 15.4141 2.254C15.4367 2.31014 15.4578 2.37343 15.5 2.5C15.5422 2.62657 15.5633 2.68986 15.5859 2.746C15.874 3.46288 16.5494 3.94963 17.3206 3.99634C17.381 4 17.4521 4 17.5944 4C17.9636 4 18.1483 4 18.2958 4.01462C19.7187 4.1556 20.8444 5.28127 20.9854 6.70421C21 6.85174 21 7.0269 21 7.37722V15.2C21 16.8802 21 17.7202 20.673 18.362C20.3854 18.9265 19.9265 19.3854 19.362 19.673C18.7202 20 17.8802 20 16.2 20H5.8C4.11984 20 3.27976 20 2.63803 19.673C2.07354 19.3854 1.6146 18.9265 1.32698 18.362C1 17.7202 1 16.8802 1 15.2V7.37722Z"
            stroke="#31006F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11 15.5C13.2091 15.5 15 13.7091 15 11.5C15 9.29086 13.2091 7.5 11 7.5C8.79086 7.5 7 9.29086 7 11.5C7 13.7091 8.79086 15.5 11 15.5Z"
            stroke="#31006F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16.352 18.006"
        >
          <g
            id="Group_1960"
            data-name="Group 1960"
            transform="translate(-1.25 -1.25)"
          >
            <g
              id="Group_1961"
              data-name="Group 1961"
              transform="translate(2 2)"
            >
              <path
                id="Path_2396"
                data-name="Path 2396"
                d="M14.377,6.951a4.951,4.951,0,1,0-9.9,0C4.475,12.727,2,14.377,2,14.377H16.852s-2.475-1.65-2.475-7.426"
                transform="translate(-2 -2)"
                fill="none"
                stroke="#31006F"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Path_2397"
                data-name="Path 2397"
                d="M19.395,40a1.65,1.65,0,0,1-2.855,0"
                transform="translate(-10.541 -24.322)"
                fill="none"
                stroke="#31006F"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
          </g>
        </svg>
      </i>
      <p class="flex justify-between w-full">
        <span>{{ n.message }}</span>
        <span style="font-weight: bold"
          >{{ n.notification_time }} {{ n.notification_date }}</span
        >
      </p>
    </div>

    <div class="flex justify-between items-center">
      <!-- ITEMS PER PAGE -->
      <vs-dropdown
        vs-trigger-click
        class="cursor-pointer mb-4 mr-4 items-per-page-handler"
      >
        <div
          style="
            font-family: 'Cairo', sans-serif !important;
            padding: 0.75rem !important;
            border-radius: 0.25rem !important;
          "
          class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
        >
          <span class="mr-2">{{ credentials.itemsPerPage }}</span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>
        <vs-dropdown-menu>
          <ul style="min-width: 8rem">
            <vs-dropdown-item
              @click="credentials.itemsPerPage = 10"
              class="m-0 p-0"
            >
              <li class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>10</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item
              @click="credentials.itemsPerPage = 20"
              class="m-0 p-0"
            >
              <li class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>20</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item
              @click="credentials.itemsPerPage = 50"
              class="m-0 p-0"
            >
              <li class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>50</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item
              @click="credentials.itemsPerPage = 100"
              class="m-0 p-0"
            >
              <li class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>100</span>
              </li>
            </vs-dropdown-item>
          </ul>
        </vs-dropdown-menu>
      </vs-dropdown>
      <vs-pagination
        :total="totalPage"
        :max-items="7"
        :size-array="10"
        v-model="credentials.pageNum"
        :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
        :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SpinnerColor from "@/views/components/table-components/SpinnerColor2.vue";
import notificationClick from "../../mixins/notificationClick";
import Modal from "../../layouts/components/Modal.vue";
import ModalRate from "../../layouts/components/ModalRate.vue";

export default {
  mixins: [notificationClick],
  data() {
    return {
      credentials: {
        itemsPerPage: 10,
        pageNum: 1,
        type: "",
      },
      titleFilter: "جميع الإشعارات",
      filters: [
        { value: "", title: "جميع الإشعارات" },
        { value: 1, title: "طرود مرتجعة" },
        { value: 2, title: "طرود تم تسليمها" },
        { value: 3, title: "إشعارات المهمات" },
        { value: 4, title: "تعديل بيانات الطرد" },
        { value: 5, title: "نصائح وتعليمات" },
      ],
      loading: false,
    };
  },
  components: {
    SpinnerColor,
    Modal,
    ModalRate,
  },
  watch: {
    "credentials.pageNum": {
      handler() {
        this.fetchNotfication(this.credentials);
      },
      deep: true,
    },
    "credentials.itemsPerPage": {
      handler() {
        this.fetchNotfication(this.credentials);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("dataList", ["notfication", "feed_count"]),
    totalPage() {
      return parseInt(this.feed_count / this.credentials.itemsPerPage);
    },
    filterArrayNotification() {
      return this.filters.filter((el) => el.title != this.titleFilter);
    },
  },
  methods: {
    ...mapActions("dataList", ["fetchNotfication"]),
    async filterNotification(item) {
      this.loading = true;
      this.credentials.itemsPerPage = 10;
      this.credentials.pageNum = 1;
      this.credentials.type = item.value;
      await this.fetchNotfication(this.credentials);
      this.titleFilter = item.title;
      this.loading = false;
    },
  },
  created() {
    this.fetchNotfication(this.credentials);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";

.notifications-header {
  margin-bottom: 1.125rem;
  h4 {
    color: #212121;
    font-size: 1.125rem;
    line-height: 2.9375rem;
    font-weight: bold;
  }
}
.notification {
  width: 100%;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0rem 0.25rem 1.5rem #0000000d;
  padding: 1.125rem 1.1875rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  .icon-notification {
    background-color: #d8d6de73;
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    border-radius: 50%;
  }
  p {
    color: #000000;
    font-size: 1rem;
  }
  .active-notification {
    background-color: #31006f0d;
    box-shadow: 0rem 0.2rem 1.5rem #31006f0d;
    .icon-notification {
      background-color: #31006f1a;
    }
  }
}
.shadow-drop {
  height: 2.625rem;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
  font-size: 0.875rem !important;
}
</style>
